import aboutImg from "./about.jpeg"
import {  Row, Col } from "react-bootstrap";


const AboutScreen = () => {
  return (
        <Row className="mt-4">
          <Col md={6}>
            <img
              src={aboutImg}
              alt="About Us"
              className="img-fluid"
            />
          </Col>
          <Col md={6}>
            <h2 className="mb-4">Welcome to ImanTiles</h2>
            <p className="text-justify">
            Welcome to Iman Tiles, your premier destination for exquisite
             outdoor tiling solutions. Founded with a passion for quality and 
             design, Iman Tiles has been transforming outdoor 
            spaces with our premium range of tiles that combine durability,
             style, and functionality.
            </p>
          
          </Col>
        </Row>
  );
};

export default AboutScreen;
