import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./layout/header/Header";
import Footer from "./layout/footer/Footer";

const App = () => {


  return (
    <>
      <Header />
      <main className="pb-3">
        {/* <Container > */}
          <Outlet />
        {/* </Container> */}
      </main>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default App;
