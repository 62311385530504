import { MdLocalShipping, MdSafetyDivider } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { BsCartFill } from "react-icons/bs";
import { BiCheckShield } from 'react-icons/bi';
import { GiStamper } from "react-icons/gi";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { Container, Row, Col } from "react-bootstrap";
import "./Services.css";

const ServicesScreen = () => {
  const iconStyle = {
    width: "45px",
    height: "45px",
    color: "var(--mainThemeColor)",

  };

  const data = [
    {
      cover: <MdLocalShipping style={iconStyle} />,
      title: "Local Delivery",
      decs: "We provide efficient and timely delivery services within the city.",
    },
    {
      cover: <MdSafetyDivider style={iconStyle} />,
      title: "Safe Payment",
      decs: "ImanTiles provides secure and reliable payment options for your convenience. .",
    },
    {
      cover: <VscWorkspaceTrusted style={iconStyle} />,
      title: "Shop With Confidence ",
      decs: "Our commitment to quality and support means you can trust every purchase with us.",
    },
    {
      cover: <GiStamper style={iconStyle} />,
      title: "Product Authenticity Assurance",
      decs: "ImanTiles guarantees product authenticity with every purchase.",
    },
    {
      cover: <BsCartFill style={iconStyle} />,
      title: "Instant Order Assistance",
      decs: "ImanTiles offers instant order assistance to ensure a smooth shopping experience.",
    },
    {
      cover: <BiSupport style={iconStyle} />,
      title: "Reliable Service",
      decs: "ImanTiles is committed to providing reliable service with every interaction. Our team ensures that each aspect of your order is handled with utmost care and efficiency.",
    },
    {
      cover: <BsCartFill style={iconStyle} />,
      title: "Instant Order Assistance",
      decs: "ImanTiles offers instant order assistance to ensure you get the help you need right away. Our support team is ready to resolve any issues or answer questions quickly.",
    },
    {
      cover: <BiSupport style={iconStyle} />,
      title: "24/7 Support ",
      decs: "ImanTiles provides 24/7 support to assist you at any time. Our dedicated team is always available to address your queries and concerns.",
    }
  ];

  return (
    <>
      <Container className="my-5">
     
        <Row className="wrapper">
          <div className="titleParaServices" >
          <h2  className="titleServices">Why Choose Iman Tiles ?</h2>
          <p  className="paraServices">We invite you to explore our range of outdoor tiles and discover how Iman Tiles can transform your outdoor spaces. Our knowledgeable team is always ready to assist you in finding the perfect tiles for your project. Contact us today to learn more about our products and services, or visit our showroom to see our tiles in person.</p>
         
          </div>
         {data.map((val, index) => {
            return (
              <Col sm={6} lg={3} className="mt-4" key={index}>
                <div className="showCardCont">
                  <div className="iconCont">{val?.cover}</div>
                  <h4 className="serviceHeading">{val?.title}</h4>
                  <p className="servicePara">{val?.decs}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default ServicesScreen;
